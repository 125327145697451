import { NavItem } from './nav-item';

export let menu: NavItem[] = [
  {
    displayName: 'Dashboard',
    iconName: 'dashboard',
    route: 'dashboard'
  },
  //  https://jossef.github.io/material-design-icons-iconfont/  URL to add icon
  //https://programmersportal.com/list-of-all-angular-mat-iconsmat-icons-library/
  // {
  //   displayName: 'Contacts',
  //   iconName: 'group',
  //   route: 'contacts',
  //   children: [
      
  //     {
  //       displayName: 'Add Contact',
  //       iconName: 'add_box',
  //       route: 'contacts/add-contact'
  //     },
  //     {
  //       displayName: 'Test Contact',
  //       iconName: 'list',
  //       route: 'contacts/test-contact'
  //     }
  //   ]
  // },
  { 
    displayName: 'Customers',
    iconName: 'group',
    route: 'customers',
    children: [
      {
        displayName: 'Add Customer',
        iconName: 'add_box',
        route: 'customers/add-customer'
      },
      {
        displayName: 'Search Customer',
        iconName: 'list',
        route: 'customers/search-customer'
      }
    ]
  },
  { 
    displayName: 'Orders',
    iconName: 'group',
    route: 'orders',
    children: [
      {
        displayName: 'Add Order',
        iconName: 'add_box',
        route: 'orders/add-order'
      },
      {
        displayName: 'Search Order',
        iconName: 'list',
        route: 'orders/search-order'
      }
    ]
  },
  { 
    displayName: 'Miscellaneous',
    iconName: 'group',
    route: 'miscellaneous-expenses',
    children: [
      {
        displayName: 'Add Expense',
        iconName: 'add_box',
        route: 'miscellaneous-expenses/add-expense'
      },
      {
        displayName: 'Manage Shirt-Fabric',
        iconName: 'arrow_right',
        route: 'miscellaneous-expenses/Fabric-Stock'
      },
      {
        displayName: 'Manage Trouser-Fabric',
        iconName: 'arrow_right',
        route: 'miscellaneous-expenses/Trouser-Fabric-Stock'
      },
       {
        displayName: 'Manage Contrast-Fabric',
        iconName: 'arrow_right',
        route: 'miscellaneous-expenses/Manage-Contrast-Fabric-Stock'
      },
      {
        displayName: 'Manage Bulk-Fabric',
        iconName: 'arrow_right',
        route: 'miscellaneous-expenses/Manage-Bulk-Fabric-Stock'
      },
      
    ]
  },
  
  
  // {
  //   displayName: 'Miscellaneous',
  //   iconName: 'add_box',
  //   route: 'miscellaneous-expenses'
  // },
  
  // {
  //   displayName: 'User',
  //   iconName: 'face',
  //   route: 'user',
  //   children: [
  //     {
  //       displayName: 'Account Info',
  //       iconName: 'account_box',
  //       route: 'user/account-info'
  //     },
  //     {
  //       displayName: 'Profile Image',
  //       iconName: 'image',
  //       route: 'user/profile-image'
  //     },
  //     {
  //       displayName: 'Add Customer',
  //       iconName: 'image',
  //       route: 'user/add-customer'
  //     }
  //   ]
  // },
  {
      displayName: 'Sign Out',
      iconName: 'highlight_off'
  }
];
