import { Component } from '@angular/core';
import { BreakpointObserver ,Breakpoints } from '@angular/cdk/layout';
import { ReactiveFormsModule,FormsModule,} from '@angular/forms';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Carey Development CRM';
  
  

  constructor() { }  
  
 

}
